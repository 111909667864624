/* Default styles */
.menu{
  background-color: black;
  color :white;
}
.menu #menu-flters {
  font-weight: 700;
  font-size: 1rem;
  padding: 0;
  margin: 0 auto;
  list-style: none;
  text-align: center;
  border-radius: 3.125rem; /* 50px / 16px = 3.125rem */
}

.menu #menu-flters li {
  cursor: pointer;
  display: inline-block;
  padding: 0.5rem 1rem 0.625rem 1rem; /* 8px / 16px = 0.5rem, 16px / 16px = 1rem, 10px / 16px = 0.625rem */
  font-weight: 700;
  font-size: 2rem;
  line-height: 1;
  color: #444444;
  margin: 0 0.1875rem 0.625rem 0.1875rem; /* 3px / 16px = 0.1875rem, 10px / 16px = 0.625rem */
  transition: all ease-in-out 0.3s;
  border: 0.125rem solid black; /* 2px / 16px = 0.125rem */
  border-radius: 3.125rem; /* 50px / 16px = 3.125rem */
}

.menu #menu-flters li:hover,
.menu #menu-flters li.filter-active {
  color: #fff;
  background-color: rgb(255, 0, 0);
}

.menu #menu-flters li:last-child {
  margin-right: 0;
}

/* Price styling */
.menu .menu-price {
  font-size: 1.25rem; /* Make price larger */
  font-weight: 600;
  margin-top: 0.5rem;
  display: flex;
  justify-content: space-between; /* Align prices on both sides */
  margin-bottom: 0.5rem; /* Ensure spacing between items */
}

.menu .menu-price .price {
  font-size: 1.5rem; /* Bigger price for single item */
  font-weight: 700;
}

.menu .menu-price .price-small {
  font-size: 1.25rem; /* Smaller font size for small size price */
  font-weight: 500;
}

.menu .menu-price .price-large {
  font-size: 1.25rem;
  color: rgb(255, 0, 0); /* Red color for large size price */
  font-weight: 600;
}

/* Enhancing the menu item content */
.menu .menu-content {
  margin-top: 1.25rem; /* Reduce margin between item name and red line */
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  position: relative;
  border-bottom: 0.125rem solid rgb(122, 0, 0); /* 2px / 16px = 0.125rem */
}

.menu .menu-name {
  font-size: 1.25rem; /* Make item name bigger */
  font-weight: 700;
  margin-bottom: 0.25rem; /* Reduce the space between item name and red line */
}

.menu .menu-ingredients {
  font-size: 1rem;
  margin-top: 0.5rem;
}

.menu-container {
  min-height: 60vh;
}

.menu .menu-content span {
  position: relative;
  z-index: 3;
  font-weight: 600;
}

/* Mobile-specific font size adjustments */
@media (max-width: 768px) {
  .menu #menu-flters li {
    font-weight: 700;
    font-size: 1rem; /* Smaller font size for tablets and smaller screens */
  }

  .menu .menu-price {
    font-size: 1.125rem; /* Adjust price font size for mobile */
  }

  .menu .menu-name {
    font-size: 1.5rem; /* Adjust item name font size for mobile */
  }

  .menu .menu-ingredients {
    font-size: 1rem; /* Smaller ingredient text for mobile */
  }

  .menu .menu-price .price-small {
    font-size: 1.125rem; /* Adjust the small price font size on tablets */
  }

  .menu .menu-price .price-large {
    font-size: 1.125rem; /* Adjust the large price font size on tablets */
  }
}

@media (max-width: 480px) {
  .menu #menu-flters li {
    font-weight: 700;
    font-size: 1rem; /* Further reduce font size for very small screens */
  }

  .menu .menu-price {
    font-size: 1rem; /* Smaller price font size for very small screens */
  }

  .menu .menu-name {
    font-size: 1.125rem; /* Smaller item name font size for very small screens */
  }

  .menu .menu-ingredients {
    font-size: 1rem; /* Adjust ingredient font size for very small screens */
  }

  .menu .menu-price .price-small {
    font-size: 1rem; /* Smaller font size for small price on small screens */
  }

  .menu .menu-price .price-large {
    font-size: 1rem; /* Adjust the large price font size on small screens */
  }
}

/* Add bottom margin to the last menu item */
.menu .menu-content:last-child {
  margin-bottom: 1rem; /* Add margin to the bottom of the last item */
}
