.bg {
  top: 0;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-color: white;
  z-index: 0;
}

.home {
  min-height: 100vh;
}
.ui {
  padding: 2em 0;
}
.footer {
  min-height: fit-content;
}

.content {
  position: relative; /* Ensure positioning context */
  z-index: 1000; /* Adjust as necessary to be on top of other content */
}
