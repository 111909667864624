@import url("https://fonts.googleapis.com/css2?family=Chewy&display=swap");

/* Navigation Bar */
#navigationBar {
  font-family:  sans-serif;
  background-color: rgba(0, 0, 0, 0); /* Transparent background by default */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3); /* Subtle shadow for depth */
  padding: 0.5rem 0; /* Add some vertical padding */
  transition: background-color 0.3s ease; /* Smooth transition when changing background */
}

/* When scrolled, apply a solid background */
#navigationBar.scrolled {
  background-color: rgba(0, 0, 0, 0.8); /* Dark background after scrolling */
}

/* Logo */
#logo {
  max-width: 13em; /* Resize for better scaling */
  filter: drop-shadow(
    0 0 10px rgba(255, 255, 255, 0.7)
  ); /* Subtle glow for logo */
  transition: filter 0.3s ease;
}

#logo:hover {
  filter: drop-shadow(
    0 0 15px rgba(255, 255, 255, 0.9)
  ); /* Glowing effect on hover */
}

/* Desktop Navigation Items */
.nav-elem {
  color: white; /* White text for contrast */
  font-weight: 600;
  font-size: 1.75rem; /* Slightly smaller than before for balance */
  margin-left: 1rem;
  position: relative; /* For underline effect */
  transition: color 0.3s ease-in-out, text-shadow 0.3s ease, transform 0.3s ease;
}

.nav-elem::after {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 0;
  height: 2px;
  background-color: orange; /* Subtle underline effect */
  transition: width 0.3s ease;
}

.nav-elem:hover {
  color: orange; /* Bright orange for hover */
  text-shadow: 0 0 10px rgba(255, 165, 0, 0.7); /* Soft glow on hover */
  transform: scale(1.05); /* Slight zoom effect */
}

.nav-elem:hover::after {
  width: 100%; /* Full underline when hovered */
}

.navbar-toggler {
  border: none;
  margin-left: auto;
}

.navbar-toggler-icon {
  width: 2em;
  height: 2em;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath stroke='%23FFFFFF' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 2em;
  background-position: center;
  display: inline-block;
  background-color: white;
  border: 2px solid white;
  border-radius: 0.25em;
  transition: background-color 0.3s ease, transform 0.3s ease; /* Smooth transitions for color and rotation */
}

/* When the menu is open, rotate the hamburger icon */
.navbar-toggler.open .navbar-toggler-icon {
  transform: rotate(90deg); /* Rotate 90 degrees when the menu is open */
}

/* On hover, rotate the icon */
.navbar-toggler-icon:hover {
  transform: rotate(90deg); /* Rotate icon on hover */
}

/* Optional: To reset hover state after clicking */
.navbar-toggler:not(.open) .navbar-toggler-icon:hover {
  transform: rotate(0deg); /* Reset rotation on hover */
}

/* Adjust background on hover */
.navbar-toggler:hover {
  background-color: rgba(255, 0, 0, 0.8);
}

/* Mobile Dropdown Menu */
@media (max-width: 768px) {
  .navbar-collapse {
    background-color: rgba(0, 0, 0, 0.9); /* Black dropdown for consistency */
    padding: 1rem;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); /* Shadow for depth */
  }

  .nav-elem {
    font-size: 1.5rem; /* Resize for mobile */
    margin: 0.5rem 0; /* Add spacing between menu items */
    text-align: center; /* Center-align menu items */
  }
}

/* Subtle Accent Line for Desktop Navbar */
.navbar::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background: linear-gradient(to right, #ff4500, #ffa500, #ff4500);
  z-index: 1;
}
