/* General Styles */
.footer-container {
  background: red;
  color: white;
  padding: 40px 20px;
  text-align: left;
  border-radius: 20px 20px 0px 0px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
}

.footer-section {
  transition: transform 0.3s ease;
}

.footer-section:hover {
  transform: translateY(-15px);
}

.footer-heading {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 20px;
  color: #ffffff;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.footer-hours {
  list-style-type: none;
  padding: 0;
}

.footer-hours li {
  margin-bottom: 3%;
  font-size: 1rem;
}

.contact {
  display: flex;
  align-items: center;
  margin-bottom: 3%;
  font-size: 1rem;
}

.contact-icon {
  margin-right: 5%;
  color: #fff;
  transition: color 0.3s;
}

.contact-icon:hover {
  color: #f05454;
}

.social-links {
  margin-top: 15px;
}

.social-icon {
  margin: 0 1rem;
  color: white;
  transition: transform 0.3s ease-in-out, color 0.3s;
}

.social-icon:hover {
  transform: scale(1.2);
  color: #f05454;
}

.footer-map {
  border: 0;
  border-radius: 15px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

/* Footer Bottom Section */
.footer-bottom {
  border-top: 1px solid #444;
  padding-top: 20px;
  text-align: center;
  font-size: 14px;
  color: #ccc;
}

.footer-bottom .copyright {
  font-size: 16px;
  color: #fff;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .footer-content {
    text-align: center;
  }

  .footer-section {
    margin-bottom: 30px;
  }

  .footer-heading {
    font-size: 22px;
  }

  .footer-map {
    display: none;
  }

  .contact {
    justify-content: center;
  }

  .social-links {
    text-align: center;
  }
}

@media (max-width: 576px) {
  .contact span {
    font-size: 14px;
  }

  .social-icon {
    font-size: 28px;
  }

  .footer-heading {
    font-size: 18px;
  }
}
