/* Review Card Styling */
.review-card {
  display: flex;
  flex-direction: column; /* Stack content */
  justify-content: space-between; /* Space out elements */
  align-items: flex-start; /* Align text to the left */
  flex-grow: 1;
  min-height: 18rem; /* Minimum height for all cards */
  max-height: 18rem; /* Fixed maximum height */
  height: 100%; /* Ensure cards stretch to fill available space */
  padding: 1rem;
  background-color: red; /* Card background */
  color: white; /* Text color */
  border-radius: 0.5rem; /* Rounded corners */
  margin-bottom: 0.625rem; /* Margin between cards */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
  overflow: hidden; /* Prevent overflow from affecting layout */
}

/* Uniform Content Spacing */
.review-body {
  flex-grow: 1; /* Stretch to fill remaining space */
  display: -webkit-box;
  -webkit-line-clamp: 4; /* Limit to 4 lines */
  -webkit-box-orient: vertical;
  overflow: hidden; /* Hide overflowing text */
  text-overflow: ellipsis; /* Add ellipsis for long text */
  line-height: 1.5;
  font-size: 1rem;
  white-space: normal; /* Wrap text */
}

/* Review Stars */
.review-stars {
  display: flex;
  align-items: center;
  margin: 0.5rem 0; /* Space around stars */
}

.review-stars .bi-star-fill {
  color: #ffd700; /* Gold for filled stars */
}

.review-stars .bi-star {
  color: #e0e0e0; /* Grey for empty stars */
}

/* Review Avatar */
.review-avatar {
  width: 4rem; /* 64px */
  height: 4rem;
  border-radius: 50%; /* Circular avatars */
  object-fit: cover; /* Maintain aspect ratio */
  background-color: black;
  margin-bottom: 1rem; /* Space below avatar */
}

/* Ensure Cards in Carousel Are Same Height */
.carousel-item {
  display: flex;
  justify-content: center; /* Center cards horizontally */
  align-items: stretch; /* Stretch cards to match each other */
}

.review-title {
  color: red;
  font-weight: 700;
  font-style: normal;
}

/* Responsive Layout */
@media (max-width: 768px) {
  .review-card {
    min-height: 20rem; /* Slightly taller cards on mobile */
    max-height: 20rem;
    padding: 1rem;
  }

  .review-avatar {
    width: 3rem; /* Smaller avatar for mobile */
    height: 3rem;
  }

  .review-body {
    -webkit-line-clamp: 6; /* Show more lines on mobile */
  }
}

@media (min-width: 769px) {
  .review-card {
    min-height: 18rem; /* Standard height for desktop */
    max-height: 18rem;
  }
}
