.video-container {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.video-container video {
  /* Make video at least 100% wide and tall */
  min-width: 100%; /* No conversion needed as it's a percentage */
  min-height: 100%; /* No conversion needed as it's a percentage */

  /* Setting width & height to auto prevents the browser from stretching or squishing the video */
  width: auto;
  height: auto;

  /* Center the video */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.overlay {
  min-height: 100vh; /* No conversion needed as it's a viewport unit */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.overlay h2 {
    color: rgb(255, 255, 255);
    font-size: 6rem; /* 700% of base font size (16px) = 7 * 16px = 112px */
    text-align: center;
    border-top: 0.1rem solid white; /* 0.1em * 16px = 1.6px */
    border-bottom: 0.1rem solid white; /* 0.1em * 16px = 1.6px */
    z-index: 1;
}

#subtitle{
  font-size: 5rem;
}
